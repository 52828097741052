import TagManager, { TagManagerArgs } from "react-gtm-module";
import { RouteList } from "Routes";
import { WindowConfirmProps } from "components/WindowConfirm";
import { ToastModel } from "types/ToastModel";
import { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ServerErrorResponse } from "results/CommandResult";
import { GlobalDataResult } from "results/account/GlobalDataResult";
import { LoginResult } from "results/account/LoginResult";
import { AccountApiService } from "services/AccountApiService";
import { PaymentsApiService } from "services/PaymentsApiService";
import { SchoolsApiService } from "services/SchoolsApiService";
import { Constants } from "utils/Constants";
import { buildDataLayer, dataTokenKey, getUserData } from "utils/GtmUtils";
import { ContentApiService } from "services/ContentApiService";
import { UsersApiService } from "services/UsersApiService";
import { Moosend } from "utils/MoosendUtils";

// api service
const schoolsService = SchoolsApiService();
const accountService = AccountApiService();
const paymentsService = PaymentsApiService();
const contentService = ContentApiService();
const usersService = UsersApiService();

export const authTokenKey = "auth-v1";

// these are temp fixes to clear out any old user data
const oldAuthTokenKeys = ["auth-token", "data-key", "token"];

for (const key of oldAuthTokenKeys) {
    localStorage.removeItem(key);
}

// get token
export const getToken = () => localStorage.getItem(authTokenKey) ?? "";
export const accessTokenFactory = () => getToken();

// initialise gtm
const tagManagerArgs: TagManagerArgs = {
    gtmId: Constants.gtm,
};

TagManager.initialize(tagManagerArgs);

// initial data layer push
const dataLayer = buildDataLayer();

TagManager.dataLayer({
    dataLayer,
});

// moosend setup
Moosend.init();

export const useGlobalContext = () => {
    const history = useHistory();

    const { getGlobalUserData } = accountService;

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
    const [authToken, setAuthToken] = useState<string>();
    const [error, setError] = useState<string>();
    const [notifications, setNotifications] = useState<Array<ToastModel>>([]);
    const [windowConfirm, setWindowConfirm] = useState<WindowConfirmProps>();
    const [userData, setUserData] = useState(getUserData());
    const [ip, setIp] = useState<string>();
    const [globalData, setGlobalData] = useState<GlobalDataResult>();

    const clearNotifications = () => setNotifications([]);

    const addNotification = useCallback((toast: ToastModel) => {
        setNotifications(old => [...old, { ...toast, id: old.length }]);
    }, []);

    // globally handle server errors
    const catchServerError = useCallback((e: unknown) => {
        // eslint-disable-next-line no-console
        console.log("Error:");
        // eslint-disable-next-line no-console
        console.log(e);
        setError("An error occurred");
    }, []);

    // update fs auth
    useEffect(() => {
        // the user is considered logged in if there's a token
        const token = localStorage.getItem(authTokenKey) ?? "";

        setIsAuthenticated(!!token);
    }, []);

    const updateAuth = useCallback((data: LoginResult) => {
        // save token
        setAuthToken(data.token);

        // set auth details
        setIsAuthenticated(!!data.token);

        setUserData(data);

        // save
        localStorage.setItem(authTokenKey, data.token);
        localStorage.setItem(dataTokenKey, JSON.stringify(data));
    }, []);

    const catchFormError = useCallback(
        (response: ServerErrorResponse) => {
            // in the case of a 404 the game has been deleted or closed
            if (response.status === 404) {
                setError("Could not find game");
            }

            if (response.errors) {
                for (const prop in response.errors) {
                    const error = response.errors[prop];

                    addNotification({
                        title: "Oh no!",
                        type: "failure",
                        description: error,
                    });
                }
            }
        },
        [addNotification],
    );

    const logout = useCallback(() => {
        setAuthToken("");
        setIsAuthenticated(false);
        setUserData(undefined);

        localStorage.removeItem(authTokenKey);
        localStorage.removeItem(dataTokenKey);

        history.push(RouteList.Home);
    }, [history]);

    // get global user data on initial page load
    useEffect(() => {
        if (!globalData && isAuthenticated !== undefined) {
            getGlobalUserData()
                .then(x => {
                    // log user out if their account does exist
                    if (isAuthenticated && !x.userExists) {
                        logout();

                        return;
                    }

                    // gtm
                    if (isAuthenticated) {
                        // gtm
                        TagManager.dataLayer({
                            dataLayer: {
                                userType: x.userType,
                                accountType: x.accountType,
                            },
                        });

                        // moosend
                        if (x.email) {
                            Moosend.identify(x.email);
                        }
                    }

                    // ip
                    setIp(x.ip);

                    setGlobalData(x);
                })
                .catch(catchServerError);
        }
    }, [catchServerError, getGlobalUserData, globalData, isAuthenticated, logout]);

    return {
        error,
        setError,
        schoolsService,
        paymentsService,
        accountService,
        contentService,
        usersService,
        notifications,
        addNotification,
        catchServerError,
        catchFormError,
        windowConfirm,
        setWindowConfirm,
        updateAuth,
        logout,
        authToken,
        isAuthenticated,
        setIsAuthenticated,
        clearNotifications,
        userData,
        setUserData,
        globalData,
        setGlobalData,
        ip,
    };
};

// automatic exports
export type IGlobalContext = ReturnType<typeof useGlobalContext>;
export const GlobalContext = createContext<IGlobalContext>({} as IGlobalContext);

type Props = {
    children: ReactNode;
};

export const GlobalContextProvider = (props: Props) => {
    const globalContext = useGlobalContext();
    return <GlobalContext.Provider value={globalContext}>{props.children}</GlobalContext.Provider>;
};
